import { render, staticRenderFns } from "./KESfCarousel.vue?vue&type=template&id=5482a215&scoped=true&"
import script from "./KESfCarousel.vue?vue&type=script&lang=js&"
export * from "./KESfCarousel.vue?vue&type=script&lang=js&"
import style0 from "./KESfCarousel.vue?vue&type=style&index=0&id=5482a215&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5482a215",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {AtomsNavigationButton: require('/var/www/packages/theme/components/atoms/NavigationButton.vue').default})
