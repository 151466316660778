import { render, staticRenderFns } from "./Gallery.vue?vue&type=template&id=3d7f1d8a&scoped=true&"
import script from "./Gallery.vue?vue&type=script&lang=ts&"
export * from "./Gallery.vue?vue&type=script&lang=ts&"
import style0 from "./Gallery.vue?vue&type=style&index=0&id=3d7f1d8a&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3d7f1d8a",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {CmsComponentsExpertStoriesGalleryCard: require('/var/www/packages/theme/components/cms-components/expert-stories/GalleryCard.vue').default,MoleculesKESfCarousel: require('/var/www/packages/theme/components/molecules/KESfCarousel.vue').default})
