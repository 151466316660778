





























import { defineComponent, PropType } from '@nuxtjs/composition-api';
import useWindowResize from '~/hooks/useResizeWindow';
import type { ExpertStory } from '~/components/cms-components/expert-stories/GalleryCard.vue';

type ExpertStories = {
  id: number;
  elements: ExpertStory[];
};

export default defineComponent({
  name: 'Gallery',
  props: {
    data: {
      type: Object as PropType<ExpertStories>,
      default: () => []
    }
  },
  setup() {
    const { isDesktop } = useWindowResize();

    const settings = {
      perView: 3
    };

    return { isDesktop, settings };
  }
});
