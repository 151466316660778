






























import { computed, defineComponent, PropType } from '@nuxtjs/composition-api';
import useWindowResize from '~/hooks/useResizeWindow';
import { getCustomFieldByKey } from '~/helpers/utils';

export type ExpertStory = {
  id: number;
  code: string;
  media: {
    media_path: string;
  }[];
  urls: Record<string, unknown>[];
  custom_fields: {
    key: string;
    value_string: string;
  }[];
};

export default defineComponent({
  name: 'GalleryCard',
  props: {
    cardData: {
      type: Object as PropType<ExpertStory>,
      required: true,
      default: () => ({})
    }
  },
  setup(props) {
    const { isDesktop } = useWindowResize();

    const imageSize = computed(() => (isDesktop.value ? 420 : 238));

    const media = computed(() => {
      const mediaItem = props.cardData.media?.[0];
      if (mediaItem?.media_path) return mediaItem;
      const urlItem = props.cardData.urls?.[0];
      if (urlItem?.media_path) return urlItem;
      return { media_path: '' };
    });

    const linkData = computed(() => props.cardData.urls?.[0]);

    const getField = (field: string) => {
      return getCustomFieldByKey(props.cardData.custom_fields, field)
        ?.value_string;
    };

    return { imageSize, media, linkData, getField };
  }
});
