





import { defineComponent, PropType, computed } from '@nuxtjs/composition-api';
import { SfCircleIcon } from '@storefront-ui/vue';

type NavigationButtonDirections = 'previous' | 'next';

const PREVIOUS_DIRECTION = 'previous';

export default defineComponent({
  name: 'NavigationButton',
  components: {
    SfCircleIcon
  },
  props: {
    direction: {
      type: String as PropType<NavigationButtonDirections>,
      default: PREVIOUS_DIRECTION
    },
    disabled: { type: Boolean, default: false }
  },
  setup(props) {
    const chevronIcon = computed(() =>
      props.direction === PREVIOUS_DIRECTION
        ? 'IconsChevronLeft24px'
        : 'IconsChevronRight24px'
    );

    return { chevronIcon };
  }
});
