//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Glide from '@glidejs/glide';
export default {
  name: 'KESfCarousel',
  props: {
    settings: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      isFirst: true,
      isLast: false,
      glide: null,
      defaultSettings: {
        type: 'slider',
        rewind: false,
        perView: 6,
        focusAt: 0,
        slidePerPage: 2,
        gap: 24,
        bound: true,
        breakpoints: {
          1223: {
            perView: 4,
            peek: {
              before: 0,
              after: 0
            }
          },
          767: {
            perView: 1,
            focusAt: 0,
            peek: {
              before: 0,
              after: 0
            }
          }
        }
      }
    };
  },
  computed: {
    mergedOptions() {
      let breakpoints = { ...this.defaultSettings.breakpoints };
      if (this.settings.breakpoints) {
        breakpoints = { ...breakpoints, ...this.settings.breakpoints };
      }
      return {
        ...this.defaultSettings,
        ...this.settings,
        breakpoints: breakpoints
      };
    }
  },
  mounted() {
    this.$nextTick(() => {
      if (!this.$slots.default) return;
      const glide = new Glide(this.$refs.glide, this.mergedOptions);
      const size = this.$slots.default.filter((slot) => slot.tag).length;
      if (size <= glide.settings.perView) {
        glide.settings.perView = size;
        glide.settings.rewind = false;
        this.$refs.controls.style.display = 'none';
      }
      glide.mount();
      glide.on('run.before', (move) => {
        const { slidePerPage, rewind, type } = this.mergedOptions;
        if (!slidePerPage) return;
        const { perView } = glide.settings;
        if (!perView > 1) return;
        const { direction } = move;
        let page;
        let newIndex;
        switch (direction) {
          case '>':
          case '<':
            page = Math.ceil(glide.index / perView);
            newIndex =
              page * perView + (direction === '>' ? perView : -perView);
            if (newIndex >= size) {
              if (type === 'slider' && !rewind) {
                newIndex = glide.index;
              } else {
                newIndex = 0;
              }
            } else if (newIndex + perView > size) {
              if (type === 'slider' && !rewind) {
                // this was initial behaviour but then it cannot
                // newIndex = glide.index;
              } else {
                newIndex = size - perView;
              }
            } else if (newIndex < 0) {
              if (type === 'slider' && !rewind) {
                // this was initial behaviour but then it cannot
                newIndex = glide.index;
              }
            }
            move.direction = '=';
            move.steps = newIndex;
        }
      });
      glide.on(['move.after'], () => {
        const size = this.$slots.default.filter((slot) => slot.tag).length;
        this.isFirst = glide.index === 0;
        this.isLast = glide.index + glide.settings.perView >= size;
      });
      this.glide = glide;
    });
  },
  methods: {
    refresh() {
      if (!this.glide) return;
      this.glide.update({
        type: 'slider',
        rewind: false,
        perView: 6,
        focusAt: 0,
        slidePerPage: 2,
        gap: 16,
        breakpoints: {
          1223: {
            perView: 3,
            peek: {
              before: 0,
              after: 50
            }
          },
          767: {
            perView: 1,
            focusAt: 0,
            peek: {
              before: 0,
              after: 0
            }
          }
        }
      });
      this.go('<<');
    },
    go(direct) {
      if (!this.glide) return;
      switch (direct) {
        case 'prev':
          this.glide.go('<');
          break;
        case 'next':
          this.glide.go('>');
          break;
      }
    }
  }
};
